import { RouteConfig } from 'vue-router';

const routes: Array<RouteConfig> = [
  {
    path: '/admin/setup_dev_token',
    component: () => import('@/views/Admin/views/SetupDevToken.vue'),
  },
  {
    path: '/admin',
    component: () => import('@/views/Admin/layout/AdminLayout.vue'),
    children: [
      {
        path: '',
        name: 'admin_dashboard',
        component: () => import('@/views/Admin/views/Dashboard.vue'),
      },
      {
        path: 'organizations',
        name: 'admin_organizations',
        component: () => import('@/views/Admin/views/OrganizationList.vue'),
      },
      {
        path: 'organizations/:id',
        name: 'admin_organization',
        component: () => import('@/views/Admin/views/OrganizationDetails.vue'),
        props: (route) => ({ id: route.params.id }),
      },
      {
        path: 'reports',
        name: 'admin_reports',
        component: () => import('@/views/Admin/views/ReportList.vue'),
      },
      {
        path: 'reports/:id',
        name: 'admin_report_details',
        component: () => import('@/views/Admin/views/ReportDetails.vue'),
        props: (route) => ({ id: route.params.id }),
      },
      {
        path: 'audio_uploads',
        name: 'admin_audio_uploads',
        component: () => import('@/views/Admin/views/AudioUploadList.vue'),
      },
      {
        path: 'audio_uploads/:id',
        name: 'admin_audio_upload_details',
        component: () => import('@/views/Admin/views/AudioUploadDetails.vue'),
        props: (route) => ({ id: route.params.id }),
      },
      {
        path: 'test_requests',
        name: 'admin_test_requests',
        component: () => import('@/views/Admin/views/TestRequestList.vue'),
      },
      {
        path: 'test_requests/:id',
        name: 'admin_test_request_details',
        component: () => import('@/views/Admin/views/TestRequestDetails.vue'),
        props: (route) => ({ id: route.params.id }),
      },
      {
        path: 'variants',
        name: 'admin_variants',
        component: () => import('@/views/Admin/views/VariantList.vue'),
      },
      {
        path: 'variants/create',
        name: 'admin_variant_create',
        component: () => import('@/views/Admin/views/VariantDetails.vue'),
      },
      {
        path: 'variants/:id',
        name: 'admin_variant_details',
        component: () => import('@/views/Admin/views/VariantDetails.vue'),
        props: (route) => ({ id: route.params.id }),
      },
      {
        path: 'users',
        name: 'admin_users',
        component: () => import('@/views/Admin/views/UserList.vue'),
      },
      {
        path: 'users/:id',
        name: 'admin_users_edit',
        component: () => import('@/views/Admin/views/UserEdit.vue'),
        props: (route) => ({ id: route.params.id }),
      },
      {
        path: 'free_subscription_tokens',
        name: 'admin_free_subscription_tokens',
        component: () => import('@/views/Admin/views/FreeSubscriptionTokensList.vue'),
      },
      {
        path: 'free_subscription_tokens/new',
        name: 'admin_free_subscription_tokens_new',
        component: () => import('@/views/Admin/views/FreeSubscriptionTokensEdit.vue'),
        props: { id: 0 },
      },
      {
        path: 'free_subscription_tokens/:id',
        name: 'admin_free_subscription_tokens_edit',
        component: () => import('@/views/Admin/views/FreeSubscriptionTokensEdit.vue'),
        props: (route) => ({ id: route.params.id }),
      },
      {
        path: 'assets',
        name: 'admin_assets',
        component: () => import('@/views/Admin/views/Assets.vue'),
      },
      {
        path: 'surveys',
        name: 'admin_surveys',
        component: () => import('@/views/Admin/views/SurveyList.vue'),
      },
      {
        path: 'surveys/new',
        name: 'admin_surveys_new',
        component: () => import('@/views/Admin/views/SurveyEdit.vue'),
        props: { id: 0 },
      },
      {
        path: 'surveys/:id',
        name: 'admin_surveys_edit',
        component: () => import('@/views/Admin/views/SurveyEdit.vue'),
        props: (route) => ({ id: route.params.id }),
      },
      {
        path: 'params',
        name: 'admin_params',
        component: () => import('@/views/Admin/views/Params.vue'),
      },
      {
        path: 'folders',
        name: 'admin_folders',
        component: () => import('@/views/Admin/views/FolderList.vue'),
      },
      {
        path: 'folders/create',
        name: 'admin_folder_create',
        component: () => import('@/views/Admin/views/FolderDetails.vue'),
      },
      {
        path: 'folders/:id',
        name: 'admin_folder_details',
        component: () => import('@/views/Admin/views/FolderDetails.vue'),
        props: (route) => ({ id: route.params.id }),
      },
      {
        path: 'problems',
        name: 'admin_problems',
        component: () => import('@/views/Admin/views/ProblemList.vue'),
      },
      {
        path: 'problems/create',
        name: 'admin_problems_create',
        component: () => import('@/views/Admin/views/ProblemDetails.vue'),
      },
      {
        path: 'problems/:id',
        name: 'admin_problems_details',
        component: () => import('@/views/Admin/views/ProblemDetails.vue'),
        props: (route) => ({ id: route.params.id }),
      },
      {
        path: 'stats',
        name: 'admin_stats',
        component: () => import('@/views/Admin/views/Stats.vue'),
      },
      {
        path: 'debug_info',
        name: 'admin_debug_info',
        component: () => import('@/views/Admin/views/DebugInfo.vue'),
      },
      {
        path: 'emails',
        name: 'admin_emails',
        component: () => import('@/views/Admin/views/Emails.vue'),
      },
    ],
  },
];

export default routes;
