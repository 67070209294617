import { AxiosInstance } from 'axios';

function createFormData(obj: object): FormData {
  const form = new FormData();
  Object.entries(obj).forEach((key_val) => form.append(key_val[0], key_val[1]));
  return form;
}

export default (axios: AxiosInstance) => ({
  getAdminStats: () => axios.get('/admin/stats').then(({ data }) => data),
  getAdminStatsMau: (start: Date, end: Date) =>
    axios.get('/admin/stats2', { params: { start, end } }).then(({ data }) => data),
  getApi1DebugInfo: () => axios.get('/admin/debug_info').then(({ data }) => data),

  getAdminFreeSubscriptionTokensList: (page: number, size: number) =>
    axios.get('/admin/free_subscription_tokens', { params: { page, size } }).then(({ data }) => data),
  getAdminFreeSubscriptionTokens: (id: number) =>
    axios.get(`/admin/free_subscription_tokens/${id}`).then(({ data }) => data),
  getAdminFreeSubscriptionTokensRedemptions: (id: number) =>
    axios.get(`/admin/free_subscription_tokens/${id}/redemptions`).then(({ data }) => data),
  getAdminFreeSubscriptionTokensCode: (id: number, from: undefined | number, to: undefined | number) =>
    axios.get(`/admin/free_subscription_tokens/${id}/code`, { params: { from, to } }).then(({ data }) => data),
  createAdminFreeSubscriptionTokens: (token: object) =>
    axios.post(`/admin/free_subscription_tokens`, token).then(({ data }) => data),
  updateAdminFreeSubscriptionTokens: (token: object) =>
    axios.put(`/admin/free_subscription_tokens/${token.id}`, token).then(({ data }) => data),

  getAdminSurveyList: (page: number, size: number) =>
    axios.get('/admin/surveys', { params: { page, size } }).then(({ data }) => data),
  getAdminSurvey: (id: number) => axios.get(`/admin/surveys/${id}`).then(({ data }) => data),
  createAdminSurvey: (survey: object) => axios.post(`/admin/surveys`, survey).then(({ data }) => data),
  deleteAdminSurveySubmissions: (deprecated_user_id: object) =>
    axios.delete(`/admin/surveys`, { data: { user_id: deprecated_user_id } }),
  updateAdminSurvey: (survey: object) => axios.put(`/admin/surveys/${survey.id}`, survey).then(({ data }) => data),
  getAdminParams: () => axios.get(`/admin/params`).then(({ data }) => data),
  getAdminProblems: (page: number, size: number) =>
    axios.get('/admin/problems', { params: { page, size } }).then(({ data }) => data),
  getAdminProblem: (id: number) => axios.get(`/admin/problems/${id}`).then(({ data }) => data),
  postAdminProblem: (payload: object) => axios.post(`/admin/problems`, payload).then(({ data }) => data),
  updateAdminProblem: (payload: object) => axios.put(`/admin/problems/${payload.id}`, payload).then(({ data }) => data),
  getAdminFolders: (page: number, size: number) =>
    axios.get('/admin/folders', { params: { page, size } }).then(({ data }) => data),
  getAdminFolder: (id: string) => axios.get(`/admin/folders/${id}`).then(({ data }) => data),
  postAdminFolder: (payload: object) => axios.post(`/admin/folders`, payload).then(({ data }) => data),
  updateAdminFolder: (payload: object) => axios.put(`/admin/folders/${payload.id}`, payload).then(({ data }) => data),

  getAdminReports: (page: number, size: number) =>
    axios.get(`/admin/reports`, { params: { page, size } }).then(({ data }) => data),
  postAdminReport: (payload: object) => axios.post(`/admin/reports`, payload).then(({ data }) => data),
  getAdminReport: (id: string) => axios.get(`/admin/reports/${id}`, { params: { id } }).then(({ data }) => data),
  getAdminAudioUpload: (id: string) =>
    axios.get(`/admin/audio_uploads/${id}`, { params: { id } }).then(({ data }) => data),
  postAdminReportPublish: (id: string) => axios.post(`/admin/reports/${id}/publish`).then(({ data }) => data),
  getAdminReportRequest: (id: string) => axios.get(`/admin/reports/${id}/request`).then(({ data }) => data),
  getAdminReportTranscript: (id: string) => axios.get(`/admin/reports/${id}/transcript`).then(({ data }) => data),
  getAdminAudioUploadsReports: (id: string) => axios.get(`/admin/audio_uploads/${id}/reports`).then(({ data }) => data),
  getAdminTestRequests: (page: number, size: number, search: string) =>
    axios.get(`/admin/test_requests`, { params: { page, size, search } }).then(({ data }) => data),
  getAdminTestRequest: (id: string) => axios.get(`/admin/test_requests/${id}`).then(({ data }) => data),
  getAdminVariants: (page: number, size: number, search: string) =>
    axios.get(`/admin/variants`, { params: { page, size, search } }).then(({ data }) => data),
  getAdminVariant: (id: string) => axios.get(`/admin/variants/${id}`).then(({ data }) => data),
  postAdminVariant: (payload: object) => axios.post(`/admin/variants`, payload).then(({ data }) => data),
  updateAdminVariant: (payload: object) => axios.put(`/admin/variants/${payload.id}`, payload).then(({ data }) => data),

  getAdminAudioUploads: (page: number, size: number, reportless: boolean) =>
    axios.get(`/admin/audio_uploads`, { params: { page, size, reportless } }).then(({ data }) => data),
  deleteAdminAudioUpload: (id: string) => axios.delete(`/admin/audio_uploads/${id}`).then(({ data }) => data),

  postAdminUserSubscriptionExpire: (user_id: string, subscriptionExpire: string) =>
    axios
      .post(`/admin/users/${user_id}/subscription`, {
        subscription_expire: subscriptionExpire,
      })
      .then(({ data }) => data),
  getAdminUserSubscriptions: (user_id: string) =>
    axios.get(`/admin/users/${user_id}/subscription`).then(({ data }) => data),
  getAdminUserAudioUploads: (user_id: string, page: number, size: number) =>
    axios.get(`/admin/users/${user_id}/audio_uploads`, { params: { page, size } }).then(({ data }) => data),
  getAdminUserDeprecated: (user_id: string) => axios.get(`/admin/users/${user_id}`).then(({ data }) => data),
  postAdminUserCompany: (user_id: string, company_id: string) =>
    axios.post(`/admin/users/${user_id}/company`, { company: company_id }).then(({ data }) => data),
  getEmailInfo: () => axios.get(`/admin/emails/info`).then(({ data }) => data),
  emailsSendEmailTemplate: (template, email, firstName) =>
    axios
      .post(`/admin/emails/emailsend`, { template_name: template, email, first_name: firstName })
      .then(({ data }) => data),
  emailsSendCogortTemplate: (template, cogort) =>
    axios.post(`/admin/emails/cogortsend`, { template_name: template, cogort }).then(({ data }) => data),
  emailsPurgeQueue: () => axios.post(`/admin/emails/purgequeue`).then(({ data }) => data),
  setupDevToken: () => axios.get(`/admin/setup_dev_token`),
});
