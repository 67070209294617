import rootAxios, { AxiosInstance } from 'axios';
import Vue from 'vue';
import { AuthRegisterRequest } from './types';
import { TopicData, CandidateAssessment, Like, GetAudiouploadList, UserLatestScore2Data } from '@/types';
import clientApi from '@/views/Dashboard/api/clientApi';
import adminApi from '@/views/Admin/api/adminApi';
import businessApi from '@/views/Organizations/Business/api/businessApi';
import paymentsApi from '@/views/Pricing/Payments/api/paymentsApi';
import progressApi from '@/views/Dashboard/views/Progress/api/progressApi';
import { Token, User } from '@/store/auth/types';
import { ICertificate } from '@/views/CertificatePage/types';

function createFormData(obj: object): FormData {
  const form = new FormData();
  Object.entries(obj).forEach((key_val) => form.append(key_val[0], key_val[1]));
  return form;
}

export const axios: AxiosInstance = rootAxios.create({
  baseURL: '/api',
});

export const api = {
  axios,

  registerAuthTokens: (store: any) => {
    axios.interceptors.request.use(
      (config) => {
        if (config.url === '/auth2/session_refresh' || config.url === '/auth2/session_start') {
          config.headers = {
            ...config.headers,
            'X-User-Agent': navigator.userAgent,
          };
          return config;
        }
        return store.dispatch('auth/getAccessToken').then((access_token: string | null) => {
          if (access_token !== null) {
            config.headers = {
              ...config.headers,
              Authorization: `Bearer ${access_token}`,
            };
          }
          return config;
        });
      },
      (error) => Promise.reject(error),
    );

    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response?.status === 401) {
          store.dispatch('auth/logout');
        }
        return Promise.reject(error);
      },
    );
  },

  registerHandlers: (vue: Vue) => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        // TODO: remove assessment route check after SMALLTALK-779.
        if (vue.$route.name !== 'assessment') {
          if (
            [
              'WrongCredentials',
              'AccountExists',
              'AccountNotFound',
              'InvalidEmail',
              'CaptchaFailed',
              'AccountIsBanned',
            ].includes(error?.response?.data?.error?.error_id)
          ) {
            return Promise.reject(error?.response?.data?.error);
          }
          vue.$snackbar.error(error?.response?.data?.error?.message || 'something went wrong');
        }
        return Promise.reject(error);
      },
    );
  },

  authRefreshToken: (refresh_token: string): Promise<Token> =>
    axios
      .post(
        '/auth2/session_refresh',
        createFormData({
          refresh_token,
        }),
      )
      .then(({ data }): Token => data),

  // Auth API
  startAnonymSession: (metrics?: { [key: string]: string }): Promise<Token> =>
    axios.post('/auth2/session_start', { ...metrics }).then(({ data }): Token => data),

  authRegister: (req: AuthRegisterRequest) =>
    axios.post('/auth2/register', createFormData(req)).then(({ data }): Token => data),

  authPasswordLogin: (email: string, password: string) =>
    axios
      .post(
        '/auth2/login',
        createFormData({
          username: email,
          password,
        }),
      )
      .then(({ data }): Token => data),

  authExchangeCode: (code: string) =>
    axios
      .post(
        '/auth2/oauthcomplete',
        createFormData({
          code,
        }),
      )
      .then(({ data }): Token => data),

  authPasswordReset: (email: string) =>
    axios.post('/auth2/password_reset_init', createFormData({ email })).then((): null => null),

  authPasswordChange: (new_password: string) =>
    axios.post('/auth2/password_change', createFormData({ password: new_password })).then((): null => null),

  authPasswordResetComplete: (code: string, password: string) =>
    axios
      .post(
        '/auth2/password_reset_complete',
        createFormData({
          code,
          password,
        }),
      )
      .then((): null => null),

  userInfo: () => axios.get('/auth/me').then(({ data }: { data: User }) => data),

  growthBookContext: () => axios.get('/auth/growthbookcontext').then(({ data }) => data),

  getUserLatestScore: () => axios.get('/dashboard/latest_score').then(({ data }) => data),

  getUserLatestScore2: (): Promise<UserLatestScore2Data> =>
    axios.get('/dashboard/latest_score2').then(({ data }: { data: UserLatestScore2Data }) => data),

  // Record
  audioUpload: (formData: FormData) =>
    axios.post('/audioupload', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    }),

  testUpload: (formData: FormData) =>
    axios.post('/testupload', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    }),

  // Problem
  getProblemsVariants: (id: string): Promise<TopicData> =>
    axios.get(`problems/variants/${id}`).then(({ data }: { data: TopicData }) => data),

  getCandidateAssessment: (id: string): Promise<CandidateAssessment> =>
    axios.get(`/assessments/assessment/${id}`).then(({ data }: { data: CandidateAssessment }) => data),

  // Report
  getAudiouploadList: (page = 0, size = 100): Promise<GetAudiouploadList> =>
    axios
      .get('/audioupload', {
        params: {
          page,
          size,
        },
      })
      .then(({ data }: { data: GetAudiouploadList }) => data),

  getAudiouploadById: (id: string): Promise<CandidateAssessment> =>
    axios.get(`/audioupload/${id}`).then(({ data }: { data: CandidateAssessment }) => data),

  getAudiouploadReport: (id: string, params: Record<string, never>): Promise<CandidateAssessment> =>
    axios.get(`/audioupload/${id}/report`, { params }).then(({ data }: { data: CandidateAssessment }) => data),

  renameAudioUpload: (id: string, data: FormData) => axios.post(`/audioupload/${id}/name`, data),

  deleteAudioUpload: (id: string) => axios.delete(`/audioupload/${id}`),

  getLikes: (id: string): Promise<Like[]> => axios.get(`/likes/${id}`).then(({ data }: { data: Like[] }) => data),

  postLike: (data: Like) => axios.post('/likes', data),

  postFeedback: (formData: FormData) =>
    axios.post('/feedback', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    }),

  getPaymentsPortalLink: () => axios.get('/payments/v2/customer_portal').then(({ data }: { data: string }) => data),

  text2speech: (text: string) => axios.get(`/text2speech?text=${text}`).then(({ data }: { data: string }) => data),

  // Dashboard
  getDashboardInfo: (): Promise<unknown> => axios.get('/dashboard/v2').then(({ data }) => data),

  // Auth-Quiz
  getQuestionnaire: (): Promise<unknown> => axios.get('/surveys/customer_info').then(({ data }) => data),

  postQuestionnaire: (data: Like) => axios.post('/surveys/customer_info', data),

  // AI-Powered Topics
  getAllTopics: (): Promise<unknown> => axios.get('/ai/topics').then(({ data }) => data),

  postTopics: (data: string[]) => axios.post('/ai/topics', data),

  getSelectedWords: (seed: number): Promise<unknown> =>
    axios.get(`/ai/selection_info?seed=${seed}`).then(({ data }) => data),

  getRandomProblem: (id: string, randomSeed: number) =>
    axios.get(`problems/problem/${id}`, { params: { seed: randomSeed } }),

  getNextPracticeItem: (variant: string): Promise<unknown> =>
    axios.get(`problems/variants/${variant}/suggest_next`).then(({ data }) => data),

  generateCertHash: (id: string): Promise<unknown> => axios.get(`/audioupload/${id}/cert`).then(({ data }) => data),

  getCertificateInfo: (hashId: string): Promise<ICertificate> => axios.get(`/cert/${hashId}`).then(({ data }) => data),

  ...clientApi(axios),
  ...businessApi(axios),
  ...adminApi(axios),
  ...paymentsApi(axios),
  ...progressApi(axios),
};
