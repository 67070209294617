import { Experiment, GrowthBook, Result } from '@growthbook/growthbook';
import axios from 'axios';
import Vue from 'vue';
import { onMetricClick } from '@/utils/metric';

export interface InstallContext {
  trackingCallback?: (experiment: Experiment<any>, result: Result<any>) => void;
  featuresEndpoint: string;
  enableDevMode: boolean;
}

const installGrowthBook = ({ featuresEndpoint, enableDevMode = false, trackingCallback }: InstallContext) => {
  Vue.prototype.$growthBook = null;
  Vue.prototype.$growthBookPromise = axios.get(featuresEndpoint).then((response) => {
    const growthBook = new GrowthBook({
      enableDevMode,
      trackingCallback,
    });
    growthBook.setFeatures(response.data.features);
    Vue.prototype.$growthBook = growthBook;
    return growthBook;
  });
};

const setGrowthBookAttributes = (attributes: Record<string, any>) =>
  Vue.prototype.$growthBookPromise.then((growthBook: GrowthBook) => {
    growthBook.setAttributes(attributes);
    return growthBook;
  });

export const setUpGrowthBook = (addOnUserChange: (arg0: (user: any) => any) => void, api: any) => {
  installGrowthBook({
    featuresEndpoint:
      import.meta.env.MODE === 'development'
        ? 'https://cdn.growthbook.io/api/features/sdk-ED7ktS4pWPq5s3sc'
        : 'https://cdn.growthbook.io/api/features/sdk-pXVVh4oCE6zcyryB',
    enableDevMode: true,
    trackingCallback: (experiment, result) => {
      onMetricClick({
        event: 'experiment_viewed',
        params: {
          experiment_id: experiment.key,
          variation_id: result.variationId,
        },
      });
    },
  });
  addOnUserChange((user) => {
    if (user) {
      return setGrowthBookAttributes(user.growthbook_context);
    }
    return api.growthBookContext().then((context: Record<string, any>) => setGrowthBookAttributes(context));
  });
};
