import { RouteConfig } from 'vue-router';
import { ApolloClient } from '@apollo/client/core/ApolloClient';
import { NormalizedCacheObject } from '@apollo/client/core';
import { isAuth, isAuthBusiness } from '@/helpers/auth';

export default (apolloClient: ApolloClient<NormalizedCacheObject>): Array<RouteConfig> => [
  {
    path: '/org',
    component: () => import(/* webpackChunkName: "business" */ '../layout/OrganizationLayout.vue'),
    beforeEnter: (to, from, next) => {
      isAuth() ? next() : next({ path: '/org/auth' });
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "Organizations" */ '../views/Organizations.vue'),
        name: 'Organizations',
      },
    ],
  },
  {
    path: '/org',
    component: () => import(/* webpackChunkName: "business" */ '../layout/Layout.vue'),
    beforeEnter: (to, from, next) => {
      isAuthBusiness() ? next() : next({ path: '*' });
    },
    children: [
      {
        path: '/org/:orgId/profile',
        props: true,
        name: 'BusinessProfile',
        component: () => import(/* webpackChunkName: "Candidates" */ '../views/BusinessProfile.vue'),
        beforeEnter: async (to, from, next) => {
          (await isAuthBusiness(apolloClient, to.params.orgId)) ? next() : next({ path: '/org' });
        },
      },
      {
        path: '/org/:orgId/candidates',
        props: true,
        name: 'Candidates',
        component: () => import(/* webpackChunkName: "Candidates" */ '../views/Assessments.vue'),
        beforeEnter: async (to, from, next) => {
          (await isAuthBusiness(apolloClient, to.params.orgId)) ? next() : next({ path: '/org' });
        },
      },
      {
        path: '/org/:orgId/billing',
        props: true,
        name: 'Billing',
        component: () => import(/* webpackChunkName: "Billing" */ '../views/Billing.vue'),
        beforeEnter: async (to, from, next) => {
          (await isAuthBusiness(apolloClient, to.params.orgId)) ? next() : next({ path: '/org' });
        },
      },
      {
        path: '/org/:orgId/tests',
        name: 'Tests',
        component: () => import(/* webpackChunkName: "Tests" */ '../views/Tests.vue'),
        beforeEnter: async (to, from, next) => {
          (await isAuthBusiness(apolloClient, to.params.orgId)) ? next() : next({ path: '/org' });
        },
        children: [
          {
            path: '/org/:orgId/tests/:id',
            name: 'AssessmentPreview',
            props: true,
            component: () =>
              import(/* webpackChunkName: "TestFlowPreview" */ '../components/tests/TestFlowPreview.vue'),
            beforeEnter: async (to, from, next) => {
              (await isAuthBusiness(apolloClient, to.params.orgId)) ? next() : next({ path: '/org' });
            },
          },
        ],
      },
      {
        path: '/org/:orgId/report/:id',
        name: 'businessReport',
        props: true,
        component: () => import(/* webpackChunkName: "businessReport" */ '@/views/Report/ReportItem/views/Report.vue'),
      },
      // TODO: check usage
      {
        path: '/business/report',
        name: 'businessReports',
        redirect: { name: 'Сandidates' },
      },
      {
        path: '/org/:orgId/integration',
        name: 'BusinessIntegration',
        props: true,
        component: () => import(/* webpackChunkName: "businessIntegration" */ '../views/Integration.vue'),
      },
      {
        path: '/org/:orgId/faq',
        name: 'BusinessFaq',
        props: true,
        component: () => import('../views/Faq.vue'),
      },
      {
        path: '/org/:orgId/contact',
        name: 'BusinessSupport',
        props: true,
        component: () => import('../views/Contact.vue'),
      },
    ],
  },
  {
    path: '/assessment',
    name: 'assessment',
    props: ({ query }) => ({ assessmentId: query.id }),
    component: () => import('@/views/Organizations/Business/views/Assessment.vue'),
    beforeEnter: ({ query }, from, next) => {
      query.id ? next() : next({ path: '*' });
    },
  },
];
