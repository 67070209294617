import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';

const httpLink = createHttpLink({
  uri: '/api/graphql/query',
});

export function createGraphqlClient(authToken: () => Promise<string | null>) {
  const authLink = setContext((operation, { headers }) =>
    authToken().then((token) => ({
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : null,
      },
    })),
  );
  const cache = new InMemoryCache();

  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache,
    connectToDevTools: true,
  });
}
